import flexibility from '@assets/svg/illustration-flexibility.svg'
import live from '@assets/svg/illustration-live.svg'
import time from '@assets/svg/illustration-time.svg'

const mission = [
    {
        id: 1,
        title: 'Gain de temps',
        content: [
            'Avec Staffea, le temps ne joue plus contre vous. Vous gagnez du temps sur vos tâches et dans la gestion de votre planning. Vérifiez vos horaires sur votre planning ajusté en temps réel et demandez des modifications ou un échange si nécessaire.',
            'Vous voulez prendre des congés ? Les demandes se font directement depuis l&nbsp;application. Vous êtes malade ou vous avez eu un accident ? Transmettez votre arrêt maladie dématérialisé sur Staffea. Toutes vos démarches sont facilitées !',
        ],
        svg: time,
    },
    {
        id: 2,
        title: 'Flexibilité',
        content: [
            "Staffea rend votre quotidien d'ASH plus flexible et agréable. Besoin d'une interversion d'horaire ? Il vous suffit d'en faire la demande via l'application pour trouver un collègue disponible de façon automatique",
            "Grâce à la bourse d'échange, vos collègues en disponibilité reçoivent une notification et peuvent accepter ou refuser l'échange d'horaires. Le planning se met automatiquement à jour si votre demande est acceptée !",
        ],
        svg: flexibility,
    },
    {
        id: 3,
        title: 'Planning en temps réel',
        content: [
            "Les erreurs de planning, c'est terminé ! Vos horaires sont mis à jour en temps réel, en fonction des ajustements d'horaires, des remplacements, des congés et des absences. Vous recevez une notification push à chaque changement de planning.",
            "Un impératif personnel ? Besoin de vous absenter ? Avec l'application mobile Staffea, faites une demande d'ajustement ou d'échange d'horaire en quelques clics. Votre quotidien devient plus simple !",
        ],
        svg: live,
    },
]

export default mission
