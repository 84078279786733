import imgDedicate from '@assets/images/img-dedicate-100.png'
import imgFeedback from '@assets/images/img-feedback.png'
import imgUser from '@assets/images/img-handle-all-optimize-experience.png'
import imgIntuitive from '@assets/images/img-intuitive-interface.png'

const content = [
    {
        id: 1,
        header: "Gérez tout depuis l'application mobile",
        title: 'Modifiez facilement votre planning !',
        text: "Facilitez votre travail d'agent de service hospitalier grâce à Staffea. La gestion de votre planning devient un jeu d'enfant. Demandez une modification d'horaire, un remplacement ou des congés en un clic. Transmettez vos arrêts maladies dématérialisés et signez électroniquement votre contrat de travail ou d'intérim.",
        image: imgUser,
        alt: 'Staffea, application mobile multifonctionnelle',
    },
    {
        id: 2,
        header: 'Accédez à une interface intuitive et ludique',
        title: 'Facilement utilisable par tous !',
        text: "Staffea ne ressemble pas aux autres plateformes de planification. Elle est ergonomique et intuitive, facilement utilisable même si vous la découvrez pour la première fois ! Accessible n'importe où et n'importe quand, vous pouvez consulter et modifier votre planning quand vous le souhaitez. L'application va devenir votre meilleure alliée dans votre quotidien d'ASH.",
        image: imgIntuitive,
        alt: 'Staffea, application mobile écrans de gestion des planning et disponibilités',
    },
    {
        id: 3,
        header: 'Consacrez-vous à 100% à votre métier',
        title: 'Vous êtes ASH, pas gestionnaire de planning.',
        text: "Négocier vos horaires, vos congés, vos remplacements : c'est terminé. L'application intelligente Staffea vous libère du temps et de la charge mentale ! Sollicitez facilement des modifications sur votre planning à votre manager, directement sur l'application. Concentrez-vous uniquement sur votre travail, plus sur votre planning !",
        image: imgDedicate,
        alt: 'Staffea, application mobile écrans de demande de congés et échange entre employés',
    },
    {
        id: 4,
        header: 'Transmettez directement vos feedbacks',
        title: 'Partagez votre expérience.',
        text: "Après une mission d'intérim ou une semaine de travail, vous pouvez partager votre expérience sur le système de feedback intégré de Staffea. Faites entendre votre voix et indiquez les éventuels événements indésirables qui se sont produits durant vos missions. Avec notre solution, vous améliorez votre qualité de vie au travail et la qualité des services rendus aux résidents.",
        image: imgFeedback,
        alt: "Staffea, application mobile écran d'édition de journée",
    },
]

export default content
