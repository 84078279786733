import pictoExchange from '@assets/svg/picto-app-exchange-hours.svg'
import pictoPlanning from '@assets/svg/picto-app-planning.svg'
import pictoRealTime from '@assets/svg/picto-app-real-time.svg'
import pictoRequest from '@assets/svg/picto-app-request.svg'

const functionalities = [
    {
        id: 1,
        subTitle: 'Vérifiez vos heures de travail',
        title: 'Accès et consultation de votre propre planning',
        image: pictoPlanning,
        list: [
            'Consultez les changements de votre planning en temps réel',
            "Soyez notifié de chaque changement d'affectation",
            "Demandez facilement des ajustements d'horaires",
        ],
    },

    {
        id: 2,
        subTitle: 'Restez flexible et protégez votre vie privée',
        title: 'Partage de vos disponibilités en temps réel',
        image: pictoRealTime,
        list: [
            'Indiquez vos disponibilités sur vos jours de repos',
            'Acceptez ou refusez les créneaux à pourvoir instantanément',
            "Faites connaître vos indisponibilités pour éviter d'être sur sollicité",
        ],
    },

    {
        id: 3,
        subTitle: 'Changez votre planning',
        title: 'Déclaration de vos demandes en ligne',
        image: pictoRequest,
        list: [
            "Demandez des ajustements d'horaires sur Staffea",
            'Envoyez vos demandes de congés',
            'Téléchargez vos arrêts maladies dématérialisés',
        ],
    },

    {
        id: 4,
        subTitle: 'Demandez des interversions',
        title: "Échanges d'horaires avec votre équipe",
        image: pictoExchange,
        list: [
            "Sollicitez un échange sur l'application",
            "Permutez vos horaires grâce à la bourse d'échanges",
            'Gagnez en flexibilité',
        ],
    },
]

export default functionalities
